/* src/Components/UserInfo.css */

.user-info {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  
  .user-info span {
    margin-right: 10px;
  }
  
  .user-info button {
    margin-left: 10px;
  }
  