body {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #2C3E50; /* Use a consistent text color */
}

/* Headings */
h1, .title {
  font-size: 2rem; /* Increase to 32px */
  font-weight: bold;
  color: #4c3a19; /* Darker shade for headings */
  margin-bottom: 1rem;
}

h2, .configuration-title {
  font-size: 1.75rem; /* 28px */
  font-weight: bold;
  color: #4c3a19;
  margin-bottom: 1rem;
}

/* Paragraph text */
p, .text-content {
  font-size: 1rem; /* 16px */
  line-height: 1.6;
  color: #4c3a19;
}











