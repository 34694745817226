/* Sidebar Styles */
.drawer-paper {
  width: 240px;
}

/* Main Content Adjustments */
/* Main Content Adjustments */
.main-content {
  margin-left: 240px; /* Adjust this value to match the sidebar width */
  padding: 20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .main-content {
    margin-left: 0; /* Remove margin for mobile, where the sidebar might be hidden or collapsible */
    padding: 20px;
  }

  /* Adjust sidebar behavior on mobile */
  .drawer-paper {
    width: 100%; /* Make the sidebar take full width on mobile */
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .main-content {
    margin-left: 0; /* Remove margin for mobile, where the sidebar might be hidden or collapsible */
    padding: 20px;
  }

  /* Adjust sidebar behavior on mobile */
  .drawer-paper {
    width: 100%; /* Make the sidebar take full width on mobile */
  }
}
